import { MetaMaskProvider } from '@metamask/sdk-react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { SimpleBackdrop } from './components';
import { LoaderProvider } from './context/LoaderContext';
import { WalletProvider } from './context/WalletContext';
import reportWebVitals from './reportWebVitals';
import { Buffer } from "buffer";
(window as any).Buffer = Buffer;


const theme = createTheme({
  typography: {
    fontFamily: 'Righteous',
    fontSize: 18,
    h1: { fontFamily: 'Righteous', color: 'white' },
    h2: { fontFamily: 'Righteous', color: 'white' },
    h3: { fontFamily: 'Righteous', color: 'white' },
    h4: { fontFamily: 'Righteous', color: 'white' },
    h5: { fontFamily: 'Righteous', color: 'white' },
    h6: { fontFamily: 'Righteous', color: 'white' },
    body1: { fontFamily: 'Righteous', color: 'white' },
    body2: { fontFamily: 'Righteous', color: 'white' },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '& .MuiOutlinedInput-input': {
            color: 'white',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'white',
          '&.Mui-focused': {
            color: 'white',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: 'white',
          },
          '& .MuiInputBase-input::placeholder': {
            color: 'white',
          },
        },
      },
    },
  }
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MetaMaskProvider debug={false} sdkOptions={{
        logging: {
          developerMode: false,
        },
        checkInstallationImmediately: true,
        dappMetadata: {
          name: "", // Replace the name with the app name
        }
      }}>
        <CssBaseline />
        <SnackbarProvider maxSnack={3}>
          <WalletProvider>
            <LoaderProvider>
              <SimpleBackdrop />
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </LoaderProvider>
          </WalletProvider>
        </SnackbarProvider>
      </MetaMaskProvider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
