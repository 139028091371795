import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import useNotification from './utilities/notificationUtils';
import { getTokenSmartContract } from './shared';
import { stakePoolContractConfig, tokenContractConfig } from './config';
import { ethers } from 'ethers';
import { useLoader } from './context/LoaderContext';
import axios, { AxiosRequestConfig } from 'axios';
import { createHmac } from './shared/createHmac';
import { useWallet } from './context/WalletContext';
import * as web3 from "@solana/web3.js";
import * as splToken from "@solana/spl-token";

export default function Bridge() {
    const [fromChain, setFromChain] = useState('');
    const [toChain, setToChain] = useState('');
    const [amount, setAmount] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const { showError, showInfo, showSuccess } = useNotification();
    const { showLoader, hideLoader } = useLoader();
    const { account, connected, connect, disconnect, connecting } = useWallet();
    const [solanaProvider, setSolanaProvider] = useState<any | null>(null);

    const connectSolanaWallet = async () => {
        const wallet = (window as any).solana;
        if (wallet && wallet.isPhantom) {
            const connectedProvider = await wallet.connect();
            setSolanaProvider(connectedProvider);
        } else {
            showError("Phantom Wallet not found");
        }
    };

    const chains = [
        { label: 'Sanko', value: 'SANKO' },
        { label: 'Solana', value: 'SOLANA' }
    ];


    const handleSubmit = async () => {

        if (fromChain === "SANKO" && !connected) {
            await connect();
            return;
        } else if (fromChain === "SOLANA" && !solanaProvider) {
            await connectSolanaWallet();
            return;
        }

        if (!fromChain || !toChain || !amount || !walletAddress) {
            showError('Please fill out all fields');
            return;
        }

        if (fromChain === toChain) {
            showError('From and To chains must be different');
            return;
        }

        if (parseFloat(amount) <= 0) {
            showError('Please enter a valid amount');
            return;
        }

        if (fromChain === "SOLANA") {
            await sendSPLToken(solanaProvider, stakePoolContractConfig.treasuryWalletSolana, Number(amount));
            return;
        }

        try {
            showLoader();
            const tokenContract = await getTokenSmartContract();
            const amountInUnits = ethers.parseUnits(amount.toString(), tokenContractConfig.decimals);
            const tx = await tokenContract.transfer(stakePoolContractConfig.treasuryWallet, amountInUnits);
            await tx.wait();
            const data = {
                userId: account,
                fromChain,
                toChain,
                amount,
                receiverAddress: walletAddress,
                transactionHash: tx.hash
            };
            const hmac = createHmac(JSON.stringify(data));

            const config: AxiosRequestConfig = {
                headers: {
                    'X-HMAC': `${hmac}`,
                    'Content-Type': 'application/json'
                }
            };

            try {
                await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/bridge/add`, data, config);
            } catch (error) {
                console.log("Faced issue during posting to API");
            }
            showSuccess("Transaction sent successfully");
        } catch (error: any) {
            showError(error?.message);
        } finally {
            hideLoader();
        }

    };

    async function sendSPLToken(provider: any, recipientAddress: string, amount: number): Promise<void> {
        if (!provider) {
            console.error("No provider found");
            return;
        }

        try {
            showLoader();
            const senderPublicKey = provider.publicKey;
            const connection = new web3.Connection(web3.clusterApiUrl("mainnet-beta"), "confirmed");
            const mintPublicKey = new web3.PublicKey(tokenContractConfig.solanaMintAddress);
            const recipientPublicKey = new web3.PublicKey(recipientAddress);

            const recipientTokenAccount = await splToken.getOrCreateAssociatedTokenAccount(
                connection,
                senderPublicKey,
                mintPublicKey,
                recipientPublicKey
            );

            const senderTokenAccount = await splToken.getOrCreateAssociatedTokenAccount(
                connection,
                senderPublicKey,
                mintPublicKey,
                senderPublicKey
            );

            const transaction = new web3.Transaction().add(
                splToken.createTransferInstruction(
                    senderTokenAccount.address,
                    recipientTokenAccount.address,
                    senderPublicKey,
                    amount * web3.LAMPORTS_PER_SOL
                )
            );

            transaction.feePayer = senderPublicKey;
            const { blockhash } = await connection.getLatestBlockhash();
            transaction.recentBlockhash = blockhash;
            console.log("Transaction prepared", transaction);

            const signedTransaction = await (window as any).solana.signTransaction(transaction);

            const signature = await connection.sendRawTransaction(signedTransaction.serialize());
            console.log("Transaction ID:", signature);
            await connection.confirmTransaction(signature);

            const data = {
                userId: solanaProvider.publicKey,
                fromChain,
                toChain,
                amount: amount.toString(),
                receiverAddress: walletAddress,
                transactionHash: signature
            };
            const hmac = createHmac(JSON.stringify(data));

            const config: AxiosRequestConfig = {
                headers: {
                    'X-HMAC': `${hmac}`,
                    'Content-Type': 'application/json'
                }
            };

            try {
                await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/bridge/add`, data, config);
            } catch (error) {
                console.log("Faced issue during posting to API");
            }
            showSuccess("Transaction sent successfully");
        } catch (error: any) {
            showError(error?.message);
        } finally {
            hideLoader();
        }
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={true}
                className="home-dialog"
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: '#EF5400',
                        borderRadius: '10px',
                        borderColor: '#EF5400',
                        borderStyle: 'outset',
                        borderWidth: '5px',
                        padding: '20px',
                    },
                }}
            >
                <DialogContent>

                    <Typography variant="h5" color="white" align="center" gutterBottom>
                        Bridge Tokens
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={3}>
                        {/* From Chain */}
                        <TextField
                            select
                            label="From Chain"
                            value={fromChain}
                            onChange={(e) => setFromChain(e.target.value)}
                            variant="outlined"
                            fullWidth
                        >
                            {chains.map((chain) => (
                                <MenuItem sx={{ color: 'black' }} key={chain.value} value={chain.value}>
                                    {chain.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        {/* To Chain */}
                        <TextField
                            select
                            label="To Chain"
                            value={toChain}
                            onChange={(e) => setToChain(e.target.value)}
                            variant="outlined"
                            fullWidth
                        >
                            {chains.map((chain) => (
                                <MenuItem sx={{ color: 'black' }} key={chain.value} value={chain.value}>
                                    {chain.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        {/* Amount */}
                        <TextField
                            label="Amount"
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />

                        {/* Wallet Address */}
                        <TextField
                            label={fromChain === "SANKO" ? "Solana Wallet Address" : "Sanko Wallet Address"}
                            value={walletAddress}
                            onChange={(e) => setWalletAddress(e.target.value)}
                            variant="outlined"
                            fullWidth
                        />

                        {/* Submit Button */}
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            fullWidth
                            sx={{
                                backgroundColor: 'white',
                                color: '#EF5400',
                                fontWeight: 'bold',
                                '&:hover': {
                                    backgroundColor: '#FFD5B5',
                                },
                            }}
                        >
                            {
                                (fromChain === "SANKO" && !connected) || (fromChain === "SOLANA" && !solanaProvider)
                                    ? "Connect wallet"
                                    : "Bridge"
                            }

                        </Button>
                        <Box sx={{ marginBottom: 1 }}>
                            <Typography variant="body1">
                                <strong>Fee Structure:</strong> A 1% fee will be applied to your transaction.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1">
                                <strong>Estimated Processing Time:</strong> The estimated bridge time is approximately <strong>48 hours</strong>.
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
